import { type NumberFormatOptions, useFormatter } from 'next-intl';

import type { SupportedCurrency } from '../types/currency';
import useCurrency from './useCurrency';

interface UseBlackFriday2024PricingState {
  greatestDiscount: string;
  lowestPrice: string;
  lowestPriceOr4: string;
}

const useBlackFriday2024Pricing = (): UseBlackFriday2024PricingState => {
  const format = useFormatter();
  const { currencyCode } = useCurrency();
  const formatterOptions: NumberFormatOptions = {
    currency: currencyCode,
    style: 'currency',
    trailingZeroDisplay: 'stripIfInteger',
  };

  const GREATEST_DISCOUNT_BY_CURRENCY: Record<SupportedCurrency, number> = {
    AUD: 325,
    CAD: 230,
    EUR: 210,
    GBP: 200,
    JPY: 28300,
    USD: 200,
  };

  const LOWEST_PRICE_BY_CURRENCY: Record<SupportedCurrency, number> = {
    AUD: 419,
    CAD: 329,
    EUR: 279,
    GBP: 249,
    JPY: 38000,
    USD: 249,
  };

  const LOWEST_PRICE_BY_CURRENCY_OR4: Record<SupportedCurrency, number> = {
    AUD: 544,
    CAD: 469,
    EUR: 389,
    GBP: 349,
    JPY: 51500,
    USD: 349,
  };

  return {
    greatestDiscount: format.number(
      GREATEST_DISCOUNT_BY_CURRENCY[currencyCode],
      formatterOptions,
    ),
    lowestPrice: format.number(
      LOWEST_PRICE_BY_CURRENCY[currencyCode],
      formatterOptions,
    ),
    lowestPriceOr4: format.number(
      LOWEST_PRICE_BY_CURRENCY_OR4[currencyCode],
      formatterOptions,
    ),
  };
};

export default useBlackFriday2024Pricing;
