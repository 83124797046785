'use client';

import { HsaFsaInlineLogo, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import { EventType } from '@/analytics/types';

import VectorImage from '../VectorImage';

const LOCATION = 'eyebrow banner';

//the hidden afilliate eyebrow by default
export const AffiliateMessage = (): JSX.Element => {
  // Fire custom event when component mounts to allow third-parties like CP to inject content
  useEffect(() => {
    const event = new CustomEvent('partnereyebrowmounted');
    window.dispatchEvent(event);
  }, []);

  return <div id="partner_eyebrow_text" data-cy="partner-eyebrow-message" />;
};
interface OnLinkClickProps {
  readonly additionalSegmentProperties?: Record<string, unknown> | undefined;
  readonly page: string;
}

const onLinkClick = async ({
  additionalSegmentProperties,
  page,
}: OnLinkClickProps): Promise<void> => {
  await window.ouraAnalytics.track(EventType.LinkClicked, {
    location: LOCATION,
    page,
    ...additionalSegmentProperties,
  });
};

export interface GenericEyebrowProps {
  readonly localeKey: MessageKey;
  readonly url?: string;
  readonly target?: string;
  readonly additionalSegmentProperties?: Record<string, unknown>;
  readonly className?: string;
}
/**
 * GenericEyebrow component driven by config in Statsig
 * */
export const GenericEyebrow = ({
  localeKey,
  url = '',
  target = '_self',
  additionalSegmentProperties,
  className = '',
}: GenericEyebrowProps): JSX.Element => {
  const t = useTranslations();
  const page = usePathname();

  return (
    <TypographyV3
      variant="body-small"
      weight="bold"
      color="inherit"
      data-cy="generic-eyebrow-message"
      className={cx(
        'inline-flex flex-wrap items-center justify-center gap-x-1 text-center',
        className,
      )}
    >
      {t.rich(localeKey, {
        link: function link(chunks) {
          return (
            <Link
              href={url}
              target={target}
              className="underline"
              onClick={() => onLinkClick({ additionalSegmentProperties, page })}
            >
              {chunks}
            </Link>
          );
        },
        icon: function icon() {
          return (
            <span
              aria-hidden="true"
              className="block align-middle"
              data-cy="hsa-fsa-inline-logo"
            >
              <HsaFsaInlineLogo />
            </span>
          );
        },
      })}
    </TypographyV3>
  );
};

export interface VectorImageProps {
  readonly imageName: string;
  readonly isNameMsgKey?: boolean;
  readonly width?: number;
  readonly height?: number;
  readonly imageClassName: string;
}
export interface VectorImageEyebrowProps extends GenericEyebrowProps {
  readonly vectorImage: VectorImageProps;
}

/**
 * VectorImageEyebrow component driven by config in Statsig
 * */
export const VectorImageEyebrow = ({
  localeKey,
  url = '',
  additionalSegmentProperties,
  className = '',
  vectorImage,
}: VectorImageEyebrowProps): JSX.Element => {
  const t = useTranslations();
  const page = usePathname();
  const { isNameMsgKey, imageClassName, imageName } = vectorImage;
  const vectorName = isNameMsgKey ? t(imageName) : imageName;
  return (
    <div
      className="flex items-center justify-center md:flex-row"
      data-cy="vector-image-eyebrow-message"
    >
      <VectorImage
        {...vectorImage}
        name={vectorName}
        className={`${imageClassName}`}
        color="inherit"
      />
      <div className="-ml-1.5 flex flex-col md:flex-row">
        <TypographyV3
          variant="body-small"
          weight="bold"
          color="inherit"
          className={cx('text-center', className)}
        >
          {t.rich(localeKey, {
            link: function link(chunks) {
              return (
                <Link
                  href={url}
                  className="underline"
                  onClick={() =>
                    onLinkClick({ additionalSegmentProperties, page })
                  }
                >
                  {chunks}
                </Link>
              );
            },
          })}
        </TypographyV3>
      </div>
    </div>
  );
};
